<template>
  <div class="order-container">
    <div class="content">
      <div class="order-success">
        <img src="~@/assets/image/music/success.png" alt="">
        <div class="info">
          <p>您已付款成功！</p>
          <div>订单号：{{orderInfo.order_no}}<i></i>订单时间：{{formDateTime(orderInfo.created_at)}}</div>
          <span>实付： <strong>¥{{Number(orderInfo.paid_amount / 100)}}</strong></span>
        </div>
      </div>
      <div class="order-details" v-loading="loading">
        <el-table ref="multipleTable" :data="[orderInfo.extend]" class="order-table" cell-class-name="cart-table-cell-class">
          <el-table-column label="商品信息" prop="name">
            <template slot-scope="{ row: { name, logo, label} }">
              <div class="music-info">
                <el-image class="music-img" :src="logo">
                  <div slot="error" class="image-slot">
                    <img src="@/assets/image/default-music-img.png" alt="" />
                  </div>
                </el-image>
                <div>
                  <span class="music-name">{{ name }}</span>
                  <p>{{label.length && label.map(i => i.name).join(',')}}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="授权信息" align="left">
            <template slot-scope="{ row: { sk_id_arr} }">
              <div v-for="item in sk_id_arr" :key="item.sk_id">{{item.sk_name}}</div>
            </template>
          </el-table-column>
          <el-table-column label="价格" align="center">
            <template>
              <span>￥{{Number(orderInfo.paid_amount / 100)}}</span>
            </template>
          </el-table-column>

          <!-- <div slot="empty">
            <el-empty description="空空如也~" />
          </div> -->
        </el-table>
        <div class="opera">
          <div class="opera-wrap">
            <span>您可以选择：</span>
            <el-button class="normal-btn" :disabled="orderInfo.apply_status != -1" @click="handleDownload">申请发票</el-button>
            <el-button class="normal-btn w-180" @click="batchRetailMusicDownload(orderInfo.order_id)">下载无水印素材</el-button>
            <el-button class="auth-btn" @click="handleRetailDownload">{{ orderInfo.extend.license_id ? '查看授权书' : '激活授权书'}}</el-button>
          </div>
          <p class="more" @click="goVip">如有更多素材需求，您可开通企业会员享更多优惠></p>
        </div>
      </div>
    </div>
    <!-- <ReatailAuthInfo ref="reatailAuthInfoRef" target="result"></ReatailAuthInfo> -->
     <!-- 授权激活成功提示弹框 -->
    <!-- <AuthsuccessDialog :dialogVisibleAuth="dialogVisibleAuth"/> -->
    <!-- 申请发票 -->
    <!-- <ApplyInvoice ref="ApplyInvoice" type="retail" @success="handleInvoiceSuccess" /> -->

  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons'
import { feachRetailOrderInfo } from '@/api/member'
import { formDateTime } from '@/utils/formDate.js';
// import ReatailAuthInfo from '@/views/member/components/RetailAuthInfo'
import { downloadAuth } from '@/api/common'
// import AuthsuccessDialog from '@/views/member/components/AuthsuccessDialog'
// import ApplyInvoice from '@/views/member/components/ApplyInvoice'
import { batchRetailMusicDownload } from '@/utils/operate'
export default {
  name: 'OrderResult',
  components: {
    // ReatailAuthInfo,
    // AuthsuccessDialog,
    // ApplyInvoice
  },
  data() {
    return {
      loading: false,
      orderInfo: {
        extend : {
          name: '',
          logo: '',
          paid_amount: 0,
          label: [],
          license_id: '',
          sk_id_arr: []
        }
      }
    }
  },
  mixins: [Buttons],
  computed: {
    dialogVisibleAuth() {
      return this.$store.state.download.auth.dialogVisibleAuth
    },
  },
  mounted() {
    this.getOrderDetail()
  },
  methods: {
    batchRetailMusicDownload,
    formDateTime,
    goVip(){
      this.$router.push('/package/page?type=2')
    },
    async getOrderDetail(){
      this.loading = true
      try {
        const res = await feachRetailOrderInfo({order_id: this.$route.params.id})
        if (res.code === 0) {
          this.orderInfo = res.data
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 零售下载授权书
    async handleRetailDownload(){
      const {extend : { license_id}} = this.orderInfo
      if (!license_id) {
        // try {
        //   this.$refs.reatailAuthInfoRef.handleOpenDialog({sk_id_arr, name, order_id})
        // } catch (e) {
        //   console.log(e)
        // }
        this.$router.push('/member/center/authorized-download')
      } else {
        downloadAuth({ license_id })
      }
    },
    // 申请开票
    handleDownload() {
      this.$router.push('/member/center/invoice')
      // let list = []
      // if (info) {
      //   list.push(info);
      //   this.$refs.ApplyInvoice.handleOpenDialog(list)
      // }
    },
    handleInvoiceSuccess(){
      this.getOrderDetail()
    },
  }
}
</script>

<style lang="scss" scoped>
.order-container {
  min-height: 100vh;
  min-width: 1000px;
  padding: 90px 0;
  background: #FAFAFA;
  .content {
    width: auto;
    margin: 0 auto;
    width: 1200px;
    .order-success {
      @include size(100%, 160px);
      background: rgba(0,173,118,.05);
      box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.1);
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(0, 173, 118, 0.5), rgba(255, 255, 255, 0)) 1 1;
      padding: 32px 0 0 214px;
      display: flex;
      img {
        @include size(120px, 100px);
        margin-right: 10px;
      }
      .info{
        p{
          font-weight: 500;
          font-size: 20px;
        }
        div{
          color: #666666;
          font-size: 14px;
          display: flex;
          align-items: center;
          margin: 18px 0 4px;
          i{
            display: inline-block;
            @include size(1px, 16px);
            background: #666666;
            margin: 0 20px;
          }
        }
        span{
          font-size: 14px;
          color: #666666;
          display: flex;
          align-items: center;
          strong {
            color: #333333;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
    .order-details {
      width: 100%;
      padding: 30px 60px 36px;
      border: 1px solid #DDDDDD;
      border-top: none;
      background: #FFFFFF;
      /deep/ {
        .order-table {
          border: 1px solid #DDDDDD;
          border-radius: 12px;
          .el-table__header {
            color: #666666;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            .el-table__cell {
              background-color: #FAFAFA;
            }
          }
          .el-table__cell {
            padding-left: 40px;
          }
          img{
            width: 70px;
            height: 70px;
          }
          .el-table__body .cell{
            color: #333333;
            font-weight: 500;
          }
        }
      }
      .music-info {
        display: flex;
        align-items: center;
        .music-img {
          margin-right: 20px;
        }
        .name {
          margin-bottom: 4px;
        }
        p{
          font-size: 12px;
          line-height: 17px;
          font-weight: 400;
          color: rgba(0,0,0,0.5);
        }
      }
      .opera {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .opera-wrap {
          display: flex;
          align-items: center;
          margin: 30px 0 10px 0;
          span {
            margin-right: 20px;
            font-size: 14px;
            color: #666666;
          }
        }
        .normal-btn, .auth-btn{
          border: none;
          padding: 0;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 20px;
          font-size: 16px;
          width: 160px;
        }
        .normal-btn {
          border: 1px solid #DDDDDD;
          color: #333333;
          margin-right: 20px;
          background: #fff;
        }
        .auth-btn{
          background: #E52A0D;
          font-weight: 500;
          color: #FFFFFF;
        }
        .w-180{
          width: 180px;
        }

        p{
          cursor: pointer;
          margin: 0;
          color: #FF8E14;
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
